<template>
  <div>
    <navbar />
    <div class="w3-row">
      <div class="w3-content w3-padding" style="max-width:500px">
        <div class="w3-card-4 w3-row">
          <div class="w3-xlarge w3-teal w3-container">Welcome</div>
          <div id="login-error" class="w3-red w3-bar w3-container w3-hide"></div>
          <div class="w3-padding w3-container w3-row">
            <router-link
              to="/schedule"
              v-if="isTeacher"
              class="w3-margin-top w3-margin-bottom w3-block w3-button w3-grey w3-border"
            >
              View Schedule
            </router-link>
            <router-link
              to="/admin"
              v-if="isAdmin"
              class="w3-margin-top w3-margin-bottom w3-block w3-button w3-grey w3-border"
            >
              Administration
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '../plugins/cognitoAuth';
import navbar from './navbar.vue';

export default {
  name: 'rootRedirect',
  data() {
    return {
      isTeacher: true,
      isAdmin: false,
    };
  },
  async created() {
    const currentUser = await auth.currentAuthenticatedUser().catch(() => null);
    if (!currentUser) {
      this.$router.push('/login');
      return;
    }
    try {
      const groups = currentUser.signInUserSession.idToken.payload['cognito:groups'];
      this.isTeacher = groups.includes('Teacher');
      this.isAdmin = groups.includes('Admin');
    } catch (error) {
      console.error(error);
    }
  },
  components: {
    navbar,
  },
};
</script>
