<template>
  <div>
    <div class="w3-display-container w3-border-bottom w3-padding" @click="expanded = !expanded">
      {{ teacher.name }}<br />
      {{ teacher.lessonRequirements.length }} Students<br />

      <div v-if="expanded">
        <span class="w3-display-topright w3-margin material-icons">expand_less</span>
        <ul class="w3-ul" @click.stop>
          <li
            v-for="(lessonRequirement, requirement) in teacher.lessonRequirements"
            v-bind:key="requirement"
          >
            {{ lessonRequirement.student.name }}:

            <input
              type="number"
              v-if="lessonRequirement.editing"
              v-model="lessonRequirement.requiredLessons"
              @keyup.enter="updateRequirement(requirement, lessonRequirement.requiredLessons)"
            />

            <span v-else @click="$set(lessonRequirement, 'editing', true)">
              {{ lessonRequirement.requiredLessons }} Lessons
            </span>

            <span
              class="w3-right material-icons"
              @click="deleteLessonRequirement(lessonRequirement.student.id)"
            >
              delete
            </span>
          </li>

          <li>
            <span
              class="w3-button w3-grey"
              @click="teacher.showAddStudentForm = !teacher.showAddStudentForm"
            >
              Add
            </span>

            <router-link
              v-bind:to="`/schedule?user=${teacher.cognitoUsername}`"
              class="w3-button w3-grey"
            >
              Schedule
            </router-link>

            <button
              v-if="!teacher.cognitoUserCreated"
              class="w3-button w3-grey"
              @click="startInvite(teacher.name)"
            >
              Invite
            </button>

            <span v-else class="w3-button w3-disabled">User invited</span>
          </li>

          <li v-if="teacher.showAddStudentForm">
            <form @keyup.enter="addLessonRequirement(teacher.id)">
              <input
                v-model="teacher.newStudent.studentName"
                class="w3-input"
                type="text"
                placeholder="Student name"
              />
              <input
                v-model="teacher.newStudent.requiredLessons"
                class="w3-input"
                type="number"
                placeholder="Number of lessons"
              />
            </form>
          </li>
        </ul>
      </div>

      <div v-else>
        <span class="w3-display-topright w3-margin material-icons">
          expand_more
        </span>
      </div>
    </div>
    <modal
      v-if="showModal.inviteTeacher"
      v-on:completed="inviteTeacher"
      v-on:hide="showModal.inviteTeacher = false"
      v-bind:form-fields="teacherInviteFormFields"
      v-bind:heading="teacherInviteHeading"
    >
    </modal>
  </div>
</template>

<script>
import modal from './modal.vue';
import api from '../plugins/api';

const baseTeacher = () => ({
  showAddStudentForm: false,
  lessonRequirements: [],
  newStudent: {
    studentName: '',
    requiredLessons: 6,
  },
});

export default {
  name: 'teacherDisplay',
  props: ['teacher'],
  data() {
    return {
      expanded: false,
      showModal: {
        inviteTeacher: false,
      },
      teacherInviteHeading: 'Invite Teacher',
      teacherInviteFormFields: {
        email: 'Email',
      },
      invitedTeacherName: '',
    };
  },

  methods: {
    async addLessonRequirement(teacherId) {
      const student = await api.createStudent({
        name: this.teacher.newStudent.studentName,
      });

      const lesson = await api.addLessonRequirement({
        studentId: student.id,
        teacherId: this.teacher.id,
        requiredLessons: parseInt(this.teacher.newStudent.requiredLessons, 10),
      });

      this.teacher.newStudent = Object.assign({}, baseTeacher().newStudent);
      this.teacher.showAddStudentForm = false;
      this.teacher.lessonRequirements.push({
        student: {
          id: lesson.student.id,
          name: lesson.student.name,
        },
        requiredLessons: lesson.requiredLessons,
      });
      this.teacher.numStudents = this.teacher.lessonRequirements.length;
    },

    updateRequirement(requirement, newValue) {
      const req = this.teacher.lessonRequirements[requirement];
      this.$set(req, 'editing', false);
      api.addLessonRequirement({
        teacherId: this.teacher.id,
        studentId: req.studentId,
        requiredLessons: parseInt(newValue, 10),
      });
    },
    deleteLessonRequirement(studentId) {
      api.deleteLessonRequirement({ teacherId: this.teacher.id, studentId });
      this.teacher.lessonRequirements = this.teacher.lessonRequirements.filter(
        l => l.studentId !== studentId
      );
    },

    startInvite(teacherName) {
      this.invitedTeacherName = teacherName;
      this.showModal.inviteTeacher = true;
    },
    async inviteTeacher({ email }) {
      await api.inviteTeacher({
        name: this.invitedTeacherName,
        email,
      });
      this.teacher.cognitoUserCreated = true;
      this.invitedTeacherName = '';
    },
  },
  components: {
    modal,
  },
};
</script>

<style scoped>
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
