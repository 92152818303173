import JSpdf from 'jspdf';
import moment from 'moment';
import { saveAs } from 'file-saver';

export function generatePdf(teacherName, fcEvents) {
  const doc = new JSpdf();
  // a4 page size in mm
  // const pageWidth = 210;
  const pageHeight = 297;

  const margin = 20;
  const spacing = 6;
  let y = 10;

  const moveDown = () => {
    y += spacing;
    if (y > pageHeight - margin) {
      doc.addPage();
      y = spacing + margin;
    }
  };

  // needed for some edge cases
  const eventSort = (a, b) => {
    const at = moment(a.start).unix();
    const bt = moment(b.start).unix();
    return at - bt;
  };

  const sortedEvents = fcEvents.sort(eventSort);

  // get a list of events for each day of the week
  const eventsByDay = Array.from(Array(7), () => []);

  sortedEvents.forEach(e => {
    eventsByDay[moment(e.start).day()].push(e);
  });

  doc.setFont('times');

  // write teacher name
  moveDown();
  doc.setFontSize(16);
  doc.text(teacherName, margin, y);
  doc.setFontSize(12);
  moveDown();

  let started = false;
  eventsByDay.forEach((events, day) => {
    if (events.length < 1) {
      return;
    }
    moveDown();
    if (started && day < 6) {
      doc.lines([[210 - margin * 2, 0]], margin, y);
      moveDown();
    }
    doc.setFontSize(14);
    doc.text(
      events[0].start.toLocaleString('en-US', {
        timeZone: 'UTC',
        weekday: 'long',
        month: 'long',
        day: 'numeric',
      })
    );
    doc.setFontSize(12);
    events.forEach(event => {
      // 11:10 PM
      const timestamp = event.start.toLocaleString('en-US', {
        timeZone: 'UTC',
        timeStyle: 'short',
      });
      const text = `${timestamp}: ${event.title}`;
      moveDown();
      doc.text(text, margin * 2, y);
    });

    started = true;
  });

  doc.save(`Schedule for ${teacherName}.pdf`);
}

export function generateTextSchedule(teacherName, fcEvents) {
  // needed for some edge cases
  const eventSort = (a, b) => {
    const at = moment(a.start).unix();
    const bt = moment(b.start).unix();
    return at - bt;
  };

  const sortedEvents = fcEvents.sort(eventSort);

  // get a list of events for each day of the week
  const eventsByDay = Array.from(Array(7), () => []);

  sortedEvents.forEach(e => {
    eventsByDay[moment(e.start).day()].push(e);
  });

  let weekStart = '';
  if (sortedEvents.length > 1) {
    weekStart = moment(sortedEvents[0].start)
      .startOf('week')
      .format('YYYY-MM-DD');
  }

  let output = '';
  const writeLine = str => {
    output += `${str}\n`;
  };

  // write teacher name
  writeLine(teacherName);

  eventsByDay.forEach(events => {
    if (events.length < 1) {
      return;
    }
    events.forEach(event => {
      // May 28 11:10 PM
      const eventStart = new Date(event.start);
      const datestamp = eventStart.toLocaleString('en-US', {
        timeZone: 'UTC',
        month: 'long',
        day: 'numeric',
      });
      const timestamp = eventStart.toLocaleString('en-US', {
        timeZone: 'UTC',
        timeStyle: 'short',
      });
      if (event.resourceId && event.resourceId !== 'ROOM_NONE') {
        writeLine(`${datestamp} ${timestamp}: ${event.title} (${event.resourceId})`);
      } else {
        writeLine(`${datestamp} ${timestamp}: ${event.title}`);
      }
    });
  });

  const file = new Blob([output], { type: 'text/plain' });
  saveAs(file, `${weekStart} ${teacherName} Schedule.txt`);
}
