<template>
  <div>
    <div class="w3-row">
      <navbar class="w3-threequarter">
        <div v-if="viewingAs" class="w3-red w3-bar-item w3-small">Viewing as {{ fullname }}</div>
        <select
          v-if="viewingAs && isAdmin"
          v-model="selectedRoom"
          @change="changeSelectedRoom"
          class="w3-select w3-bar-item w3-right"
        >
          <option value="ROOM_NONE" selected>View specific room</option>
          <option v-for="room in roomList" v-bind:key="room.name" v-bind:value="room.name">
            {{ room.name }}
          </option>
        </select>
        <div
          v-if="viewingAs && isAdmin && selectedRoom !== 'ROOM_NONE'"
          class="w3-bar-item w3-button w3-right w3-red w3-margin-right"
          @click="mergeAll"
        >
          Merge All
        </div>
        <!-- <div
          v-if="viewingAs"
          class="w3-bar-item w3-button w3-right w3-red w3-margin-right"
          @click="downloadPDF"
        >
          Download PDF
        </div> -->
        <div
          v-if="viewingAs"
          class="w3-bar-item w3-button w3-right w3-red w3-margin-right"
          @click="downloadText"
        >
          Raw Text Schedule
        </div>
      </navbar>
    </div>
    <div class="w3-row">
      <div class="w3-col">
        <FullCalendar
          class="w3-container w3-threequarter"
          ref="fullCalendar"
          style="z-index: 0"
          schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
          :plugins="calendarPlugins"
          :header="{
            left: 'title',
            center: '',
            right: 'prev,next',
          }"
          :theme="false"
          defaultView="timeGridWeek"
          datesAboveResources="true"
          minTime="07:00:00"
          timeZone="UTC"
          :selectable="true"
          eventColor="#15781B"
          :droppable="true"
          :allDaySlot="false"
          slotDuration="00:15:00"
          slotLabelInterval="01:00:00"
          height="auto"
          :editable="true"
          :events="this.fcEvents"
          @eventClick="this.eventClick"
          @eventDrop="this.rescheduleEvent"
          @eventResize="this.rescheduleEvent"
          @eventReceive="this.scheduleNewEvent"
          :resources="this.getResources"
        />
      </div>
      <div
        class="unselectable w3-green w3-col w3-sidebar w3-quarter w3-hide-small"
        style="right: 0; top: 0"
      >
        <h2 class="w3-margin-left">To Schedule</h2>
        <div id="toschedule">
          <div
            class="needs-scheduling-item w3-card w3-teal w3-margin-left w3-margin-right"
            v-for="(student, index) in studentsList"
            v-bind:key="index"
            v-bind:ref="student.id"
          >
            <div class="w3-panel">
              <div class="w3-large">{{ student.name }}: {{ student.required }} Lessons</div>
              <div class="w3-tag w3-orange">
                {{ student.required - scheduledCounts[student.id] }} Remaining
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="w3-button w3-teal w3-right w3-margin-right">Validate</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';

import api from '../plugins/api';
import auth from '../plugins/cognitoAuth';
import navbar from './navbar.vue';
import { generatePdf, generateTextSchedule } from '../plugins/pdfGenerator';
// import '../assets/touchpunch.min';

const baseEvent = () => ({
  id: '',
  resourceId: 'ROOM_NONE',
  title: '',
  start: '2018-01-01T10:00:00-04:00',
  end: '2018-01-01T11:00:00-04:00',
  // non-standard fields, will be preserved by FullCalendar
  teacherName: '',
  studentName: '',
  roomId: '',
});

export default {
  name: 'calendar',
  data() {
    return {
      teacherId: '',
      teacherUsername: '',
      studentsList: [],
      fc: undefined,
      scheduledCounts: {},
      username: '',
      fullname: '',
      viewingAs: false,
      isAdmin: false,
      selectedRoom: 'ROOM_NONE',
      roomList: [],
      windowStart: '',
      windowEnd: '',
      selectedRoomEvents: [],
      calendarPlugins: [dayGridPlugin, interactionPlugin, resourceTimeGridPlugin, timeGridPlugin],
      ready: false,
    };
  },
  computed: {
    sidebarStyle() {
      return {
        right: '0px',
        top: this.navbarHeight,
      };
    },
  },

  created() {
    this.initialLoad();
  },

  methods: {
    async initialLoad() {
      const currentUser = await auth.currentAuthenticatedUser();
      this.teacherUsername = this.$route.query.user;
      if (!this.teacherUsername) {
        this.teacherUsername = currentUser.username;
      } else {
        this.viewingAs = true;
        this.isAdmin = true;
        api.getRooms().then(rooms => {
          this.roomList = rooms;
        });
      }

      this.fc = this.$refs.fullCalendar.getApi();

      let teacher;
      try {
        teacher = await api.getTeacher({
          where: {
            username: this.teacherUsername,
          },
          includeLessonRequirements: true,
        });
        this.fullname = teacher.name;
        this.teacherId = teacher.id;
      } catch (error) {
        if (/not authorized/.test(error.response.data.errorMessage)) {
          this.$router.push('/schedule');
          this.$router.go();
        }
        return;
      }
      this.studentsList = teacher.lessonRequirements.map(r => ({
        id: `${r.student.id}`,
        name: r.student.name,
        required: r.requiredLessons,
        eventData: {
          title: r.student.name,
          duration: '01:00',
          studentId: `${r.studentId}`,
          teacherId: teacher.id,
        },
      }));
      this.studentsList.forEach(student => {
        this.$set(this.scheduledCounts, student.id, this.scheduledCounts[student.id] || 0);
      });
      this.studentsList.sort((a, b) => a.name.localeCompare(b.name));

      this.ready = true;
      this.fc.refetchEvents();
      await this.$nextTick();

      this.studentsList.forEach(student => {
        const draggable = new Draggable(this.$refs[`${student.id}`][0], {
          eventData: student.eventData,
        });
      });
    },

    eventResourceId(event) {
      const resources = event.getResources();
      if (!resources || resources.length === 0) {
        return null;
      }
      return event.getResources()[0].id;
    },

    toISO(time) {
      return new Date(time).toISOString();
    },
    gotNavbarHeight(height) {
      this.navbarHeight = `${height}px`;
    },

    // getEvents loads events from the database and returns them in fc format
    async getEvents(start, end) {
      if (!this.ready) {
        return [];
      }
      this.windowStart = this.toISO(start);
      this.windowEnd = this.toISO(end);

      const lessons = await api.getLessons({
        teacherId: this.teacherId,
        windowStart: this.windowStart,
        windowEnd: this.windowEnd,
      });

      api.getScheduledCounts({ teacherId: this.teacherId }).then(counts => {
        Object.keys(counts).forEach(studentId => {
          this.$set(this.scheduledCounts, studentId, counts[studentId]);
        });
      });

      return lessons.map(this.toFCEvent);
    },

    // fcEvents is called by fc to load events for the current time range
    async fcEvents({ start, end }) {
      if (!this.ready) {
        return [];
      }
      const newEvents = await this.getEvents(start, end);
      // Remove all existing events to prevent duplicates when switching back
      // and forth between weeks
      this.fc.getEvents().forEach(event => {
        event.remove();
      });
      return newEvents;
    },

    toFCEvent(dbEvent) {
      const result = Object.assign(
        baseEvent(),
        dbEvent,
        JSON.parse(dbEvent.associatedData || '{}')
      );
      if (dbEvent.roomId) {
        result.resourceId = dbEvent.roomId;
      }
      result.studentId = `${result.studentId}`;
      return result;
    },

    async addLessonsForRoom() {
      const lessonsForRoom = await api.getLessons({
        roomId: this.selectedRoom,
        windowStart: this.windowStart,
        windowEnd: this.windowEnd,
      });
      lessonsForRoom.forEach(lesson => {
        if (lesson.teacherId === this.teacherId) {
          return;
        }
        const event = Object.assign(baseEvent(), lesson, JSON.parse(lesson.associatedData || '{}'));
        event.resourceId = this.selectedRoom;
        event.color = 'grey';
        event.editable = false;
        event.resourceEditable = false;
        const newEvent = this.fc.addEvent(event);
        this.selectedRoomEvents.push(newEvent);
      });
    },
    changeSelectedRoom() {
      const selectedRoomEventIDs = this.selectedRoomEvents.map(e => e.id);
      const filterFunction = event => selectedRoomEventIDs.includes(event.id);
      this.fc
        .getEvents()
        .filter(filterFunction)
        .forEach(event => event.remove());
      this.selectedRoomEvents = [];

      this.fc.refetchResources();
      if (this.selectedRoom !== 'ROOM_NONE') {
        this.fc.changeView('resourceTimeGridWeek');
        this.addLessonsForRoom();
      } else {
        this.fc.changeView('timeGridWeek');
      }
    },
    getResources(fetchInfo, resolve) {
      const roomNone = {
        id: 'ROOM_NONE',
        title: 'None',
      };
      if (this.viewingAs && this.isAdmin && this.selectedRoom !== 'ROOM_NONE') {
        resolve([
          roomNone,
          {
            id: this.selectedRoom,
            title: this.selectedRoom,
          },
        ]);
      } else {
        resolve([roomNone]);
      }
    },
    async scheduleNewEvent({ event }) {
      // Set new event to disabled/uneditable, since it doesn't have a real ID yet
      // and dragging or resizing it causes duplicates to be created
      event.setProp('editable', false);
      event.setProp('color', 'grey');
      const originalTitle = event.title;
      event.setProp('title', 'Saving...');

      let response;
      try {
        response = await api.createLesson({
          studentId: parseInt(event.extendedProps.studentId, 10),
          teacherId: event.extendedProps.teacherId,
          roomId: this.eventResourceId(event) || 'ROOM_NONE',
          start: this.toISO(event.start),
          end: this.toISO(event.end),
          associatedData: JSON.stringify({
            title: originalTitle,
          }),
        });
      } catch (e) {
        console.error(e);
        event.remove();
        return;
      }
      this.scheduledCounts[event.extendedProps.studentId] += 1;
      // TODO: FCv4 has a bug where it's not possible to setProp with id correctly
      // instead, delete the original event and add a new one
      // event.setProp('id', response.id);
      event.remove();
      const fcEvent = this.toFCEvent(response);
      fcEvent.editable = true;
      event = this.fc.addEvent(fcEvent);
      event.setResources(['ROOM_NONE']);

      // Can't find how to disable externally draggable objects
      // TODO: re-enable
      // const student = this.studentsList.find(s => s.id === event.extendedProps.studentId);
      // if (this.scheduledCounts[event.extendedProps.studentId] >= student.required) {
      //   $(this.$refs[student.id][0]).draggable('disable');
      // }
    },
    async rescheduleEvent({ event, newResource, revert }) {
      if (event.editable === false) {
        revert();
      }
      const updatedLesson = {
        id: event.id,
        teacherId: this.teacherId,
        start: this.toISO(event.start),
        end: this.toISO(event.end),
      };
      if (!!newResource) {
        updatedLesson.roomId = newResource.id;
      }

      try {
        await api.updateLesson(updatedLesson);
      } catch (error) {
        console.error(error.response);
        revert();
      }
    },
    eventToLesson(event) {
      return {
        id: event.id,
        studentId: parseInt(event.studentId, 10),
        teacherId: event.teacherId,
        roomId: this.eventResourceId(event) || 'ROOM_NONE',
        start: this.toISO(event.start),
        end: this.toISO(event.end),
        associatedData: JSON.stringify({
          title: event.title,
        }),
      };
    },
    mergeAll() {
      const events = this.fc.getEvents();
      const eventIds = [];
      events.forEach(event => {
        if (!this.eventResourceId(event) || this.eventResourceId(event) === 'ROOM_NONE') {
          event.setResources([this.selectedRoom]);
          eventIds.push(event.id);
        }
      });
      api.batchUpdateRooms({
        lessonIds: eventIds,
        roomId: this.selectedRoom,
      });
    },
    eventClick({ event }) {
      if (event.editable === false || event.resourceEditable === false) {
        return;
      }
      if (this.viewingAs && this.isAdmin && this.selectedRoom !== 'ROOM_NONE') {
        if (this.eventResourceId(event) === 'ROOM_NONE') {
          event.setResources([this.selectedRoom]);
        } else {
          event.setResources(['ROOM_NONE']);
        }
        const lesson = this.eventToLesson(event);
        api.updateLesson(lesson);

        return;
      }
      api.deleteLesson({
        id: event.id,
        teacherId: this.teacherId,
      });
      this.scheduledCounts[event.extendedProps.studentId] -= 1;
      // this.makeDraggable(event.extendedProps.studentId);
      event.remove();
    },
    // makeDraggable(studentId) {
    //   this.$refs[studentId].draggable({
    //     scroll: false,
    //     zIndex: 2500,
    //     revert: true,
    //     helper: 'clone',
    //     revertDuration: 0,
    //     stack: '.draggable',
    //     appendTo: 'body',
    //     cursorAt: { top: 0, left: 0 },
    //   });
    // },
    // TODO: jspdf is different now - is it actually worth fixing?
    // downloadPDF() {
    //   const events = this.fc.getEvents();
    //   generatePdf(this.fullname, events);
    // },
    async downloadText() {
      const events = await this.getEvents(this.windowStart, this.windowEnd);
      generateTextSchedule(this.fullname, events);
    },
  },
  components: {
    navbar,
    FullCalendar,
  },
};
</script>

<style scoped>
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>

<style lang="scss" scoped>
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
</style>
