<template>
  <div>
    <navbar />
    <div class="w3-row">
      <div class="w3-content w3-padding" style="max-width:500px">
        <div class="w3-card-4 w3-row">
          <div class="w3-xlarge w3-teal w3-container">Login</div>
          <div id="login-error" class="w3-red w3-bar w3-container w3-hide"></div>
          <div class="w3-padding w3-row w3-center">
            <div v-if="newPasswordRequired">
              <form v-on:keyup.enter="changePassword" v-on:submit.prevent>
                <input
                  class="w3-input w3-border-bottom w3-section"
                  type="text"
                  v-model="enteredUsername"
                  placeholder="Username"
                />
                <input
                  class="w3-input w3-border-bottom w3-section"
                  type="password"
                  v-model="enteredPassword"
                  placeholder="Old Password"
                />
                <input
                  class="w3-input w3-border-bottom w3-section"
                  type="password"
                  v-model="newPassword"
                  placeholder="New Password"
                />
              </form>
              <button class="w3-button w3-grey w3-border" @click="changePassword">
                Change Password
              </button>
            </div>
            <div v-else>
              <form v-on:keyup.enter="login" v-on:submit.prevent>
                <input
                  class="w3-input w3-border-bottom w3-section"
                  type="text"
                  v-model="enteredUsername"
                  placeholder="Username"
                />
                <input
                  class="w3-input w3-border-bottom w3-section"
                  type="password"
                  v-model="enteredPassword"
                  placeholder="Password"
                />
              </form>
              <button class="w3-button w3-grey w3-border" @click="login">Login</button>
            </div>
          </div>
          <!-- <div class="w3-center w3-small w3-padding-bottom" @click="forgotPassword">
          Forgot password?
        </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '../plugins/cognitoAuth';
import navbar from './navbar.vue';

export default {
  name: 'login',
  data() {
    return {
      enteredUsername: '',
      enteredPassword: '',
      newPassword: '',
      newPasswordRequired: false,
      user: {},
      isReady: false,
    };
  },
  methods: {
    async login() {
      this.enteredUsername = this.enteredUsername.toLowerCase();
      try {
        this.user = await auth.signIn(this.enteredUsername, this.enteredPassword);
        if (this.user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.newPasswordRequired = true;
          return;
        }
      } catch (error) {
        if (error.message) {
          alert(error.message);
        } else {
          alert(`An unrecognized error occurred: ${error}`);
        }
        return;
      }
      this.loggedIn();
    },
    async changePassword() {
      try {
        this.user = await auth.completeNewPassword(this.user, this.newPassword);
      } catch (error) {
        if (error.message) {
          alert(error.message);
        } else {
          alert(`An unrecognized error occurred: ${error}`);
        }
        return;
      }
      this.loggedIn();
    },
    loggedIn() {
      this.$router.push('/');
    },
    async forgotPassword() {
      const username = prompt('Enter your username');
      const response = await auth.forgotPassword(username);
    },
  },
  components: {
    navbar,
  },
};
</script>
