<template>
  <div id="app">
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
    <router-view/>
  </div>
</template>

<script>
import auth from './plugins/cognitoAuth';

export default {
  name: 'App',
  methods: {
    logout() {
      auth.signOut();
    },
  },
};
</script>

<style>
.clickable {
  cursor: pointer;
}
</style>
