<template>
  <div>
    <navbar />
    <div class="w3-row">
      <div class="w3-content w3-padding" style="max-width:500px">
        <div class="w3-card-4 w3-row">
          <div class="w3-xlarge w3-teal w3-container">Login</div>
          <div id="login-error" class="w3-red w3-bar w3-container w3-hide"></div>
          <div class="w3-padding w3-row w3-center">
            <button class="w3-button w3-grey w3-border" @click="doRequest">
              Do Request
            </button>
            <button class="w3-button w3-grey w3-border" @click="getAttributes">
              Log user attributes
            </button>
            <button class="w3-button w3-grey w3-border" @click="verifyEmail">
              Verify email
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../plugins/api';
import auth from '../plugins/cognitoAuth';
import navbar from './navbar.vue';

export default {
  name: 'landing',
  data() {
    return {
      enteredUsername: '',
      enteredPassword: '',
      newPassword: '',
      newPasswordRequired: false,
      user: {},
    };
  },
  methods: {
    async doRequest() {
      api.noop();
    },
    async getAttributes() {
      const currentUser = await auth.currentAuthenticatedUser();
      await auth.updateUserAttributes(currentUser, {
        name: 'Daniel Lawrence',
      });
      auth.userAttributes(currentUser);
    },
    async verifyEmail() {
      const currentUser = await auth.currentAuthenticatedUser();
      await auth.verifyUserAttribute(currentUser, 'email');
      const code = prompt('Enter the code emailed to you');
      auth.verifyUserAttributeSubmit(currentUser, 'email', code);
    },
  },
  components: {
    navbar,
  },
};
</script>
