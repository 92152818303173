import axios from 'axios';
import auth from './cognitoAuth';

class RequestMaker {
  static async perform(method, message = {}) {
    const credentials = await auth.currentSession();
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/${method}`, message, {
      headers: {
        'X-Api-Key': process.env.VUE_APP_API_KEY,
        Authorization: credentials.idToken.jwtToken,
      },
    });
    return response.data;
  }
}

export default new Proxy({}, {
  get(_, method) {
    return message => RequestMaker.perform(method, message);
  },
});
