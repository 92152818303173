<template>
  <div>
    <navbar> </navbar>
    <div class="w3-col">
      <div class="w3-row w3-content">
        <div class="w3-mobile w3-card-4">
          <div class="w3-bar w3-teal w3-padding">
            <div class="w3-large w3-bar-item">Rooms</div>
            <button class="w3-bar-item w3-button w3-red w3-right" @click="showModal.addRoom = true">
              New Room
            </button>
            <button
              class="w3-bar-item w3-button w3-red w3-right w3-margin-right"
              @click="showModal.uploadRoomList = true"
            >
              Upload Room List
            </button>
          </div>
          <div v-for="room in roomList" v-bind:key="room.name">
            <div class="w3-display-container w3-border-bottom w3-padding">
              {{ room.name }} <span v-if="room.displayName">({{ room.displayName }})</span>
              <span class="w3-right material-icons" @click="deleteRoom(room)">
                delete
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
      v-if="showModal.addRoom"
      v-on:completed="addRoom"
      v-on:hide="showModal.addRoom = false"
      v-bind:form-fields="addRoomFormFields"
      v-bind:heading="addRoomHeading"
    >
    </modal>

    <modal
      v-if="showModal.uploadRoomList"
      v-on:uploaded="roomListUploaded"
      v-on:hide="showModal.uploadRoomList = false"
      v-bind:show-file-upload="true"
      v-bind:heading="uploadRoomListHeading"
    >
    </modal>
  </div>
</template>

<script>
import modal from "./modal.vue";
import api from "../plugins/api";
import navbar from "./navbar.vue";

export default {
  name: "rooms",
  data() {
    return {
      showModal: {
        addRoom: false,
        uploadRoomList: false
      },
      roomList: [],
      addRoomHeading: "Add Room",
      uploadRoomListHeading: "Upload Rooms List",
      addRoomFormFields: {
        name: "Name",
        displayName: "Display Name (optional)"
      }
    };
  },

  async created() {
    this.roomList = await api.getRooms();
    this.sortRooms();
  },

  methods: {
    sortRooms() {
      this.roomList.sort((a, b) => a.name.localeCompare(b.name));
    },
    async addRoom(room) {
      const response = await api.createRoom(room);
      this.roomList.push(response);
      this.sortRooms();
    },
    async deleteRoom(room) {
      this.roomList = this.roomList.filter(r => r.name !== room.name);
      try {
        await api.deleteRoom({ name: room.name });
      } catch (error) {
        this.roomList.push(room);
      }
      this.sortRooms();
    },
    async roomListUploaded(file) {
      await api.uploadRoomList({ file });
      this.roomList = await api.getRooms();
      this.sortRooms();
    }
  },
  computed: {},
  components: {
    modal,
    navbar
  }
};
</script>

<style scoped>
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
