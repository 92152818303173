<template>
  <div>
    <navbar />
    <div class="w3-col">
      <div class="w3-row w3-content">
        <div class="w3-mobile w3-card-4">
          <div id="filedrop" class="w3-bar w3-teal w3-padding">
            <div class="w3-large w3-bar-item">Teachers</div>
            <button
              class="w3-bar-item w3-button w3-red w3-right material-icons"
              @click="showModal.addTeacher = true"
            >
              person_add
            </button>
            <button
              class="w3-bar-item w3-button w3-red w3-margin-right w3-right"
              @click="showModal.inviteTeachers = true"
            >
              Invite Teachers
            </button>
            <button
              class="w3-bar-item w3-button w3-red w3-margin-right w3-right"
              @click="showModal.uploadTeachers = true"
            >
              Upload Teacher Data
            </button>
            <button
              class="w3-bar-item w3-button w3-red w3-margin-right w3-right"
              @click="showModal.createAdmin = true"
            >
              Create New Admin
            </button>
            <router-link
              class="w3-bar-item w3-button w3-red w3-margin-right w3-right"
              v-bind:to="`/admin/rooms`"
            >
              Rooms
            </router-link>
          </div>
          <div v-for="teacher in teacherList" v-bind:key="teacher.id">
            <teacher-display v-bind:teacher="teacher" />
          </div>
        </div>
      </div>
    </div>

    <modal
      v-if="showModal.addTeacher"
      v-on:completed="addTeacher"
      v-on:hide="showModal.addTeacher = false"
      v-bind:form-fields="teacherAddFormFields"
      v-bind:heading="teacherAddHeading"
    ></modal>

    <modal
      v-if="showModal.inviteTeacher"
      v-on:completed="inviteTeacher"
      v-on:hide="showModal.inviteTeacher = false"
      v-bind:form-fields="teacherInviteFormFields"
      v-bind:heading="teacherInviteHeading"
    ></modal>

    <modal
      v-if="showModal.uploadTeachers"
      v-on:uploaded="teacherFileUploaded"
      v-on:hide="showModal.uploadTeachers = false"
      v-bind:show-file-upload="true"
      v-bind:heading="uploadTeachersHeading"
    ></modal>

    <modal
      v-if="showModal.inviteTeachers"
      v-on:uploaded="teacherInviteFileUploaded"
      v-on:hide="showModal.inviteTeachers = false"
      v-bind:show-file-upload="true"
      v-bind:heading="uploadTeacherInvitesHeading"
    ></modal>

    <modal
      v-if="showModal.createAdmin"
      v-on:completed="createAdmin"
      v-on:hide="showModal.createAdmin = false"
      v-bind:form-fields="adminCreateFormFields"
      v-bind:heading="createAdminHeading"
    ></modal>
  </div>
</template>

<script>
import modal from './modal.vue';
import api from '../plugins/api';
import navbar from './navbar.vue';
import teacherDisplay from './teacherDisplay.vue';

const baseTeacher = () => ({
  expanded: false,
  showAddStudentForm: false,
  lessonRequirements: [],
  newStudent: {
    studentName: '',
    requiredLessons: 6,
  },
});

export default {
  name: 'admin',
  data() {
    return {
      showModal: {
        addTeacher: false,
        inviteTeacher: false,
        uploadTeachers: false,
        inviteTeachers: false,
        createAdmin: false,
      },
      teacherList: [],
      teacherAddHeading: 'Add Teacher',
      teacherInviteHeading: 'Invite Teacher',
      uploadTeachersHeading: 'Upload Teacher and Student Data',
      uploadTeacherInvitesHeading: 'Invite Teachers from List',
      createAdminHeading: 'Create new admin user',
      teacherAddFormFields: {
        name: 'Name',
      },
      teacherInviteFormFields: {
        email: 'Email',
      },
      adminCreateFormFields: {
        name: 'Name',
        email: 'Email',
      },
      invitedTeacherName: '',
    };
  },

  created() {
    this.refreshTeachers();
  },

  methods: {
    async refreshTeachers() {
      const teachers = await api.getTeachers();
      const processedTeachers = teachers.map(teacher =>
        Object.assign({}, baseTeacher(), {
          id: teacher.id,
          name: teacher.name,
          lessonRequirements: teacher.lessonRequirements || [],
          cognitoUserCreated: teacher.cognitoUserCreated,
          cognitoUsername: teacher.cognitoUsername,
        })
      );
      this.teacherList = processedTeachers;
      this.sortTeachers();
    },
    sortTeachers() {
      const last = xs => xs[xs.length - 1];
      this.teacherList.sort((a, b) =>
        last(a.name.split(' ')).localeCompare(last(b.name.split(' ')))
      );
      this.teacherList.forEach(teacher => {
        teacher.lessonRequirements.sort((a, b) => a.student.name.localeCompare(b.student.name));
      });
    },
    async addTeacher(input) {
      const response = await api.createTeacher(input);
      const newTeacher = Object.assign({}, baseTeacher(), {
        id: response.id,
        name: response.name,
        cognitoUsername: response.cognitoUsername,
        cognitoUserCreated: response.cognitoUserCreated,
      });
      this.teacherList.push(newTeacher);
      this.sortTeachers();
    },

    deleteLessonRequirement(teacherId, studentId) {
      // OUTDATED
      api.deleteLessonRequirement({ teacherId, studentId });
      const teacher = this.teacherList.find(t => t.id === teacherId);
      teacher.lessonRequirements = teacher.lessonRequirements.filter(
        l => l.studentId !== studentId
      );
    },
    startInvite(teacherName) {
      this.invitedTeacherName = teacherName;
      this.showModal.inviteTeacher = true;
    },
    async inviteTeacher({ email }) {
      await api.inviteTeacher({
        name: this.invitedTeacherName,
        email,
      });
      this.teacherList.find(t => t.name === this.invitedTeacherName).cognitoUserCreated = true;
      this.invitedTeacherName = '';
    },
    async teacherFileUploaded(file) {
      await api.handleTeacherStudentCSV({ file });
      this.refreshTeachers();
    },

    teacherInviteFileUploaded(file) {
      api.handleTeacherInviteCSV({ file });
    },

    async createAdmin(userDetails) {
      await api.createAdminUser(userDetails);
    },
  },
  computed: {
    isExpanded(teacherId) {
      return this.expandedTeachers[teacherId] || false;
    },
  },
  components: {
    modal,
    navbar,
    teacherDisplay,
  },
};
</script>

<style scoped>
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
