import Vue from 'vue';
import Router from 'vue-router';
import rootRedirect from '@/components/rootRedirect.vue';
import login from '@/components/login.vue';
import landing from '@/components/landing.vue';
import admin from '@/components/admin.vue';
import calendar from '@/components/calendar.vue';
import help from '@/components/help.vue';
import rooms from '@/components/rooms.vue';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'rootRedirect',
      component: rootRedirect,
    },
    {
      path: '/login',
      name: 'login',
      component: login,
    },
    {
      path: '/home',
      name: 'landing',
      component: landing,
    },
    {
      path: '/admin',
      name: 'admin',
      component: admin,
    },
    {
      path: '/admin/rooms',
      name: 'rooms',
      component: rooms,
    },
    {
      path: '/schedule',
      name: 'schedule',
      component: calendar,
    },
    {
      path: '/help',
      name: 'help',
      component: help,
    },
  ],
});
