<template>
  <div class="w3-modal w3-show" @click="hide">
    <div class="w3-modal-content w3-animate-top" @click.stop>
      <header class="w3-container w3-blue">
        <span @click="hide" class="w3-button w3-display-topright">&times;</span>
        <h3>{{ heading }}</h3>
      </header>
      <div class="w3-panel w3-margin-right w3-margin-left">
        <form
          @keyup.enter.once="completed"
          v-on:submit.prevent>
          <input v-if="showFileUpload" type="file" v-on:input="uploaded"/>
          <div v-for="(description, identifier) in formFields" v-bind:key="identifier">
            <label>{{description}}</label>
            <input
              class="w3-input w3-section"
              v-bind:key="identifier"
              type="text"
              v-model="input[identifier]"
              v-bind:placeholder="description"
              v-bind:id="identifier"/>
          </div>
        </form>
        <button class="w3-button w3-right w3-dark-grey w3-section" @click="completed">
            Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'formInput',
  props: ['formFields', 'heading', 'showFileUpload'],
  data() {
    return {
      input: this.computedInput(),
      uploadedFile: undefined,
    };
  },
  methods: {
    computedInput() {
      if (!this.formFields) {
        return {};
      }
      return Object.keys(this.formFields).reduce((accumulator, key) => (
        Object.assign(accumulator, { [key]: '' })
      ), {});
    },
    completed() {
      this.$emit('completed', this.input);
      this.$emit('uploaded', this.uploadedFile);
      this.input = this.computedInput();
      this.hide();
    },
    async uploaded(event) {
      this.uploadedFile = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(event.target.files[0]);
        reader.onload = e => resolve(e.target.result);
        reader.onerror = reject;
      });
    },
    hide() {
      this.$emit('hide');
    },
  },
};
</script>

<style scoped>
.form-box {
  margin: 20px 50px;
}
.row {
  margin: 2% 4% 2% 4%;
}
</style>
