<template>
  <div ref="navbar" class="w3-display-container w3-bar w3-green">
    <router-link v-bind:to="'/'" class="w3-bar-item w3-large">Mansfield@GMCMF</router-link>
    <div v-if="isLoggedIn" class="w3-bar-item w3-red w3-right w3-button" @click="logout">
      Sign out
    </div>
    <div v-if="isLoggedIn" class="w3-bar-item w3-right">{{ userDisplayName }}</div>
    <slot></slot>
  </div>
</template>

<script>
import auth from '../plugins/cognitoAuth';

export default {
  name: 'navbar',
  data() {
    return {
      userDisplayName: '',
      isLoggedIn: false,
    };
  },
  created() {
    this.updateDisplay();
  },
  mounted() {
    this.$emit('navbar-height', this.$refs.navbar.clientHeight);
  },
  methods: {
    async updateDisplay() {
      const currentUser = await auth.currentAuthenticatedUser().catch(() => null);
      if (!currentUser) {
        return;
      }
      this.isLoggedIn = true;
      const userAttributes = await auth.userAttributes(currentUser);
      const nameAttr = userAttributes.find(attr => attr.Name === 'name') || {};
      this.userDisplayName = nameAttr.Value;
    },
    logout() {
      auth.signOut();
      this.$router.push('/login');
    },
  },
};
</script>
